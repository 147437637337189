import andreVlok from "./assets/andre-vlok.png";
import swyftLogo from "./assets/swyftlogo-lrg.svg";
import navIcon from "./assets/nav-icon.svg";
import closeIcon from "./assets/close-icon.svg";
import certificate1 from "./assets/certificate (1).jpg";
import certificate2 from "./assets/certificate (2).jpg";
import certificate3 from "./assets/certificate (3).jpg";
import certificate4 from "./assets/certificate (4).jpg";
import certificate5 from "./assets/certificate (5).jpg";
import certificate6 from "./assets/certificate (6).jpg";
import "./styles/unfolded.css";
import React, { useState, useRef } from "react";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const experienceRef = useRef(null);
  const toolkitRef = useRef(null);
  const qualificationsRef = useRef(null);
  const projectsRef = useRef(null);

  function toggleNav() {
    setIsOpen((prevOpen) => !prevOpen);
  }

  function scrollToExperience() {
    experienceRef.current.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  }

  function scrollToToolkit() {
    toolkitRef.current.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  }

  function scrollToQualifications() {
    qualificationsRef.current.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  }

  function scrollToProjects() {
    projectsRef.current.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  }

  return (
    <div>
      {/* Nav */}
      {!isOpen && (
        <img
          onClick={toggleNav}
          className="pointer navIcon"
          src={navIcon}
          alt="Open Nav"
        />
      )}
      {isOpen && (
        <div>
          <img
            onClick={toggleNav}
            className="pointer closeIcon"
            src={closeIcon}
            alt="Close Nav"
          />
          <nav className="n-pad-x w-pad-top">
            <img src={swyftLogo} alt="Swyft Logo" />
            <h4>Swyfteam</h4>
            <br />
            <h6 onClick={scrollToExperience}>Experience</h6>
            <h6 onClick={scrollToToolkit}>Toolkit</h6>
            <h6 onClick={scrollToQualifications}>Qualifications</h6>
            <h6 onClick={scrollToProjects}>Projects</h6>
          </nav>
        </div>
      )}

      {/* Header */}
      <header className="center">
        <div className="mobi-pad">
          <div className="n-mar-y">
            <img
              className="brdr-white n-mar-bottom"
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "100%",
              }}
              src={andreVlok}
            />
            <h1 className="txt-center">
              Need a <i>high-performance</i> <br /> full stack web app?
            </h1>
            <div className="w-mar-y">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/swyfteam/"
              >
                <button>Yes, let's chat</button>
              </a>
            </div>
          </div>
          <h2>↓</h2>
        </div>
      </header>

      <main>
        {/* About */}
        <div className="w-mar-bottom">
          <section className="center">
            <h4>
              As a qualified Full Stack Web Developer & UX Designer with over 4+
              years experience in the web3, fintech and investment space, I
              approach my work with a collaborative cross-functional dynamic by
              blending a unique skillset of technical proficiencies and creative
              capabilities. My deep empathy and a perceptive nature has allowed
              me to develop solutions that prioritize aligning user's needs with
              business goals. Advancements in technology has always been at the
              forefront of my thinking and the driving force behind my passion
              to innovate.
            </h4>
          </section>
        </div>

        {/* Experience */}
        <div className="w-mar-y" ref={experienceRef}>
          <h2>Experience</h2>
          <div className="m-mar-y">
            <article className="brdr-2 m-pad round m-mar-bottom">
              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/D4E0BAQHt2lqK88DwKQ/company-logo_100_100/0/1667428722249?e=1692230400&v=beta&t=akZDLLE3_OeXjSDJpSeODXsKMl6mTleIP0BiHrX_V3U"
                    alt=""
                  />
                  Full Stack Web Developer
                </h4>
                <h5 className="no-mar-bottom">Swyfteam · Self-employed</h5>
                <p>
                  <i>Jan 2023 - Present · 5 mos</i>
                </p>
                <p>
                  As a full stack web developer, I have built several web
                  applications for clients across various industries. One of my
                  most notable projects was building a full-stack web app using
                  React.js as the front-end framework, MongoDB as the back-end
                  database, and Auth0 as the authentication layer. I also
                  integrated Hotjar for user analytics, Vercel for deployment,
                  and MetaMask for crypto wallet integration. This project
                  showcased my skills in working with cutting-edge technologies
                  and delivering a seamless user experience.
                </p>
                <p>
                  In addition, I have also developed a custom SCSS styling
                  framework and a front-end web app using Vue.js, SCSS/CSS, and
                  Netlify for deployment. This project was another highlight of
                  my development skills, and it demonstrated my ability to work
                  with multiple frameworks and technologies to create a visually
                  appealing and user-friendly interface.
                </p>
                <p>
                  Overall, my experience in full-stack web development has given
                  me a diverse skill set and the ability to create high-quality
                  web applications that meet the needs of clients in various
                  industries.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Full Stack Web Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Fintech</div>
                  <div className="bg-clr-2 round n-pad-x tag">Advertising</div>
                  <div className="bg-clr-2 round n-pad-x tag">Investment</div>
                  <div className="bg-clr-2 round n-pad-x tag">Web3</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Client Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Project Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Agile Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Testing and Quality Assurance
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Continuous Learning
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4E0BAQFv86DY7LD5RQ/company-logo_100_100/0/1567431821170?e=1692230400&v=beta&t=FlZVGNMT83OaTBBXL8YNO7TEU5B0lBgikx5HMML_J_8"
                    alt=""
                  />
                  UX Lecturer
                </h4>
                <h5 className="no-mar-bottom">
                  Academy of Digital Arts · Part-time
                </h5>
                <p>
                  <i>Jan 2023 - Mar 2023 · 3 mos</i>
                </p>
                <p>
                  As a lecturer of Design Thinking and User Experience modules
                  for higher education students, I bring a wealth of expertise
                  to the classroom. With a passion for teaching and a deep
                  knowledge of the industry, I've developed comprehensive
                  curriculums that empower students to hone their skills in UX
                  Design and design thinking methodologies. Additionally, I've
                  been responsible for the development of various assessment
                  tools and have leveraged my communication skills to foster an
                  engaging and collaborative learning environment. My dedication
                  to professional development ensures that I stay abreast of the
                  latest advancements in the field, which I am able to share
                  with my students. Alongside my pedagogical skills, my
                  interpersonal skills make me an effective mentor, as I strive
                  to help students achieve their full potential.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Teaching and Instruction
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Curriculum Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Design Thinking
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Experience Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Pedagogy</div>
                  <div className="bg-clr-2 round n-pad-x tag">Assessment</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Industry Knowledge
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Interpersonal Skills
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Professional Development
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4D0BAQEWqemY343rnA/company-logo_100_100/0/1680164121968?e=1692230400&v=beta&t=ZtCrftwOFLcQq53PsLzFqz1KEXUU2VszMFnAszsRf-0"
                    alt=""
                  />
                  UX Engineer
                </h4>
                <h5 className="no-mar-bottom">Revix · Full-time</h5>
                <p>
                  <i>May 2022 - Jan 2023 · 9 mos</i>
                </p>
                <p>
                  As a UX Engineer in the cryptocurrency and investment
                  industry, I specialize in developing strategic user
                  experiences that empower people to manage their wealth with
                  ease - which is the Revix mission and vision. While working as
                  part of the Revix product team, I tackled the challenge of
                  designing and developing user experiences for the company's
                  interest-bearing savings accounts in Bitcoin, USD Coin, and
                  Ethereum. I also organized and conducted a full-scale user
                  testing with Maze of the accounts and flow to identify areas
                  for improvement and optimization. Through close collaboration
                  with the front-end development team, I utilized my technical
                  skills in front-end development to create technical
                  documentation that provided them with specifications to ensure
                  adherence to material design guidelines and iOS Human
                  Interface design guidelines, specifically focusing on how
                  components should be built, styled and used to ensure
                  consistent front-end delivery accross various devices,
                  operating systems and browsers. As someone who is committed to
                  continuous learning, I stay up-to-date with advancements in
                  cryptocurrency and investment, as well as front-end
                  development and design trends in the industry.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Experience Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Product Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Reporting</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Project Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Cryptocurrency
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Technical Writing
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Material Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Collaboration
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Problem Solving
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4E0BAQGFM8O5jjGOlA/company-logo_100_100/0/1549286171914?e=1692230400&v=beta&t=d21i8znsaf66APQv3dyThFhJi9MWAiZtgGm-JYiya08"
                    alt=""
                  />
                  Head of UX Design
                </h4>
                <h5 className="no-mar-bottom">
                  Journey Customer Innovation · Full time
                </h5>
                <p>
                  <i>Nov 2021 - May 2022 · 7 mos</i>
                </p>
                <p>
                  As Head of UX, I directed and managed all aspects of the user
                  experience design process, including information architecture,
                  customer analysis, mock-ups, prototypes, testing, and
                  technical briefs. I successfully executed an A/B test on the
                  primary revenue driver (micro-finance/loan applications),
                  which led to a significant increase in conversions. Reporting
                  directly to the CEO, I also managed content and collaborated
                  with the outsourced development team to ensure seamless
                  execution of design strategies as development deliverables. My
                  leadership and communication skills played a pivotal role in
                  fostering a collaborative work environment that encouraged
                  problem-solving and creative solutions.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Experience Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Leadership</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Content Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">A/B Testing</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Technical Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Customer Analysis
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Reporting</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Collaboration
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Problem Solving
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4E0BAQGFM8O5jjGOlA/company-logo_100_100/0/1549286171914?e=1692230400&v=beta&t=d21i8znsaf66APQv3dyThFhJi9MWAiZtgGm-JYiya08"
                    alt=""
                  />
                  UX Engineer
                </h4>
                <h5 className="no-mar-bottom">
                  Journey Customer Innovation · Full time
                </h5>
                <p>
                  <i>Dec 2020 - Nov 2021 · 1 yr</i>
                </p>
                <p>
                  As a UX Engineer for Journey Customer Innovation, I was
                  responsible for creating exceptional user experiences for
                  Journey Customer Innovation and its partners, including
                  Sanlam, BetterBond, and DirectAxis. I utilized my skills in
                  User Experience Design, Frontend Development, and Testing to
                  develop components in VUE.js, test new feature deploys, and
                  manage user analytics. In addition, I successfully created
                  marketing emails to drive user engagement. My contributions
                  led to my promotion as Head of UX Design, where I gained
                  valuable experience in management and collaboration in a
                  fast-paced startup environment. Throughout my experience at
                  Journey Customer Innovation, I have consistently demonstrated
                  strong communication, problem-solving, and collaboration
                  skills.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Experience Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Frontend Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Testing</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Email Marketing
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Analytics</div>
                  <div className="bg-clr-2 round n-pad-x tag">Management</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Startup Experience
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Collaboration
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Problem Solving
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4D0BAQH5YlYbbPTCmw/company-logo_100_100/0/1679475610250?e=1692230400&v=beta&t=PeW2LArc1j2881JcbD1j7aYQ49AqZfq2RPIuHoj2NBY"
                    alt=""
                  />
                  UX Engineer
                </h4>
                <h5 className="no-mar-bottom">VLOKFLOW · Self-employed</h5>
                <p>
                  <i>Jan 2020 - Dec 2020 · 1 yr</i>
                </p>
                <p>
                  As a Freelance UX Engineer, I have successfully completed
                  design and development projects for clients while proving my
                  ability to market myself to new networks and maintain
                  pre-existing relationships. I am particularly proud of my
                  success in learning VUE.js and developing a custom SCSS
                  framework called unfolded, which has been deployed to Netlify.
                  My expertise in User Experience Design and Frontend
                  Development has been invaluable in developing creative
                  solutions for clients. In addition, my marketing skills have
                  helped me establish a strong client base while my
                  collaboration and problem-solving skills have ensured that
                  each project is completed to the highest standards. My passion
                  for continuous learning ensures that I stay up-to-date with
                  the latest design trends and technological advancements.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Experience Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Frontend Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Custom Framework Development
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">SCSS</div>
                  <div className="bg-clr-2 round n-pad-x tag">Marketing</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Collaboration
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Problem Solving
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Deployment</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Continuous Learning
                  </div>
                </div>
                <hr className="m-mar-y" />
              </div>

              <div>
                <h4 className="nowrap">
                  <img
                    className="logox"
                    src="https://media.licdn.com/dms/image/C4D0BAQFI7tHNSOtPBw/company-logo_100_100/0/1519916416041?e=1692230400&v=beta&t=dI3_xIIPgKdj_61a8kIabeVxNcWdeBPnnbeGgh2ne5g"
                    alt=""
                  />
                  UX/UI & Graphic Designer
                </h4>
                <h5 className="no-mar-bottom">Ma-Afrika Hotels · Internship</h5>
                <p>
                  <i>Nov 2019 · 1 mo</i>
                </p>
                <p>
                  As a UX/UI and Graphic Designer intern at Ma-Afrika Hotels, I
                  successfully redesigned website interfaces, created brand
                  identities, and managed social media campaigns for the hotel
                  group's six properties. I demonstrated proficiency in SEO best
                  practices, Google Business, Analytics, and Search Console.
                  Additionally, I developed excellent social media management
                  skills, including running high engagement Facebook and
                  Instagram campaigns. I successfully launched festive menus for
                  Helena's Restaurant and Stellenbosch Kitchen. During this
                  internship, I reported directly to the Marketing Manager,
                  which allowed me to hone my project management and
                  communication skills. Other skills I utilized were analytical
                  and creative skills, as well as collaboration and reporting
                  skills.
                </p>
                <div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    User Interface Design
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Branding</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Project Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Marketing</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Social Media Management
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Communication
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Analytical Skills
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Creativity</div>
                  <div className="bg-clr-2 round n-pad-x tag">
                    Collaboration
                  </div>
                  <div className="bg-clr-2 round n-pad-x tag">Reporting</div>
                </div>
              </div>
            </article>
          </div>
        </div>

        {/* Tech tools */}
        <div className="w-mar-y" ref={toolkitRef}>
          <h2 className="txt-center">My tech toolkit</h2>
          <div className="grid_3-6 m-mar-y">
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Bootstrap_logo.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/3/3d/CSS.3.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/9/96/Sass_Logo_Color.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg"
              alt=""
            />
            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/4/4f/PhpMyAdmin_logo.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://www.svgrepo.com/show/327408/logo-vercel.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/9/97/Netlify_logo_%282%29.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/c/c2/GitHub_Invertocat_Logo.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/30_Atlassian_logo_logos-1024.png"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/e/e0/Git-logo.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://cardea.imgix.net/media/lists/cover_images/alchemy-mark-blue-gradient.jpg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/9/98/Solidity_logo.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/768px-Adobe_XD_CC_icon.svg.png"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg"
              alt=""
            />

            <img
              className="logos"
              src="https://dvc8if9ahants.cloudfront.net/media/images/Maze-logo-web.original.png"
              alt=""
            />

            <img
              className="logos"
              src="https://logos-world.net/wp-content/uploads/2021/11/Hotjar-New-Logo.png"
              alt=""
            />

            <img
              className="logos"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/768px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519"
              alt=""
            />
          </div>

          <div className="grid_1-4">
            <article className="brdr-2 m-pad round fixed-height">
              <h5>Frontend Development</h5>
              <ul>
                <li>Javascript, React, Vue.js</li>
                <li>Tailwind, Bootstrap</li>
                <li>CSS, SASS/SCSS</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>Backend Development</h5>
              <ul>
                <li>Node.js</li>
                <li>MongoDB</li>
                <li>phpMyAdmin</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>Deployment</h5>
              <ul>
                <li>Vercel & Netlify</li>
                <li>Github & Bitbucket</li>
                <li>Git version control</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>Blockchain</h5>
              <ul>
                <li>Ethereum API (Alchemy)</li>
                <li>Web3 Wallet Integrations</li>
                <li>Solidity</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>UX Design</h5>
              <ul>
                <li>Figma & Figjam </li>
                <li>Adobe XD & Illustrator</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>User Testing & Analytics</h5>
              <ul>
                <li>Maze User Testing</li>
                <li>Hotjar Analytics</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>Productivity & Workflow</h5>
              <ul>
                <li>Microsoft & Google Suite</li>
                <li>Asana, Trello</li>
              </ul>
            </article>
            <article className="brdr-2 m-pad round fixed-height">
              <h5>IDE’s</h5>
              <ul>
                <li>Visual Studio Code</li>
                <li>Adobe Dreamweaver</li>
              </ul>
            </article>
          </div>
        </div>

        {/* Qualifications */}
        <div className="w-mar-y" ref={qualificationsRef}>
          <h2 className="txt-center">Qualifications</h2>
          <div className="m-mar-y">
            <div className="grid_1-3 certificates">
              <img
                className="certificate brdr-2 round"
                src={certificate1}
                alt=""
              />
              <img
                className="certificate brdr-2 round"
                src={certificate2}
                alt=""
              />
              <img
                className="certificate brdr-2 round"
                src={certificate3}
                alt=""
              />
              <img
                className="certificate brdr-2 round"
                src={certificate4}
                alt=""
              />
              <img
                className="certificate brdr-2 round"
                src={certificate5}
                alt=""
              />
              <img
                className="certificate brdr-2 round"
                src={certificate6}
                alt=""
              />
            </div>
          </div>
        </div>

        {/* Personal projects */}
        <div className="w-mar-y" ref={projectsRef}>
          <h2 className="txt-center">Personal projects</h2>
          <div className="m-mar-y">
            <article className="txt-center brdr-2 m-pad round m-mar-bottom">
              <h2>Swyftouch</h2>
              <h4>
                Swyftouch is a full-stack web application I designed and
                developed, utilizing a range of cutting-edge technologies
                including React.js as the front-end framework, MongoDB for
                back-end/database alogn with Vercel's serverless functions,
                Auth0 for the authentication layer, Hotjar for user analytics,
                Vercel for deployment, and MetaMask for seamless crypto wallet
                integration.
                <br />
                <br />
                Visit the project here:&nbsp;
                <a href="https://swyftouch.com/">
                  <b>
                    <i>Swyftouch</i>
                  </b>
                </a>
              </h4>
              <br />
              <iframe
                className="round"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="100%"
                height="800"
                src="https://www.swyftouch.com/"
                allowfullscreen
              ></iframe>
            </article>

            <article className="txt-center brdr-2 m-pad round m-mar-bottom">
              <h2>Auction Inc.</h2>
              <h4>
                Auction Inc. is a landing page and a lead generating web form
                (posting leads to a Google Sheet) that I developed with for the
                Auction Inc. property group. The landing page is designed to
                expand their reach into the Tswhane metropoliltan area. <br />
                <br />
                Visit the project here:&nbsp;
                <a href="https://auctioninc-tswhane.netlify.app/">
                  <b>
                    <i>Auction Inc.</i>
                  </b>
                </a>
              </h4>
              <br />
              <iframe
                className="round"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="100%"
                height="800"
                src="https://auctioninc-tswhane.netlify.app/"
                allowfullscreen
              ></iframe>
            </article>

            <article className="txt-center brdr-2 m-pad round m-mar-bottom">
              <h2>FBMA</h2>
              <h4>
                Franco Bello Music Academy is a website I developed using VUE.js
                for a music academy. <br />
                <br />
                Visit FBMA here:&nbsp;
                <a href="https://franco-bello-music.netlify.app/#/">
                  <b>FBMA</b>
                </a>
              </h4>
              <br />
              <iframe
                className="round"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="100%"
                height="800"
                src="https://franco-bello-music.netlify.app/#/"
                allowfullscreen
              ></iframe>
            </article>

            <article className="txt-center brdr-2 m-pad round m-mar-bottom">
              <h2>Monify</h2>
              <h4>
                Monify is a fintech startup selling financial products on behalf
                of its partners to South African Governement Employees. I
                performed a full scale UX audit, and redesigned and developed
                their loan application process.
                <br />
                <br />
                Visit the project here:&nbsp;
                <a href="https://vlox-project-personal-loans.netlify.app/#/hi-fi-prototype">
                  <b>MONIFY</b>
                </a>
              </h4>
              <br />
              <iframe
                className="round"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="100%"
                height="800"
                src="https://vlox-project-personal-loans.netlify.app/#/hi-fi-prototype"
                allowfullscreen
              ></iframe>
            </article>

            <article className="txt-center brdr-2 m-pad round m-mar-bottom">
              <h2>Unfolded</h2>
              <h4>
                Unfolded is a custom SCSS styling framework I developed
                consisting of over 2800 lines of SCSS and implemented using
                Vue.js. The framework was successfully deployed on Netlify.
                <br />
                <br />
                Visit it here:&nbsp;
                <a href="https://unfolded.netlify.app/#/">
                  <b>
                    <i>Unfolded</i>
                  </b>
                </a>
              </h4>
              <br />
              <iframe
                className="round"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="100%"
                height="800"
                src="https://unfolded.netlify.app/#/"
                allowfullscreen
              ></iframe>
            </article>
          </div>
        </div>

        {/* CTA */}
        <div className="w-mar-y center">
          <h2 className="txt-center">Need an app built?</h2>
          <div className="m-mar-y">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/swyfteam/"
            >
              <button>Yes, let's chat</button>
            </a>
          </div>
        </div>
      </main>
      <footer className="center">
        <h5 className="n-mar-bottom no-mar-top">&copy; Swyfteam 2023</h5>
      </footer>
    </div>
  );
}

export default App;
